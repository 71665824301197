import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Button } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { apiPost } from "../../api";
import { restaurant_verifyOTP } from "../../api/constants";

export default function Otp() {
  const navigate = useNavigate();
  const location = useLocation();
  const { email, OTP, restaurantId, hash } = location.state || {};

  const [otp, setOtp] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await apiPost({
        url: restaurant_verifyOTP,
        data: {
          OTP: otp,
          hash,
          email,
        },
      });
      if (response?.data?.status) {
        console.log(response.data);
        const { accessToken } = response.data.data;
        toast.success(response.data?.message);
        navigate("/ResetPassword", { state: { accessToken } });
      } else {
        toast.error(response.data?.message);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={6} className="loginform">
          <div className="loginmain_div maxwidth500 mx-auto">
            <div className="headlogin_div mb-4">
              <h2>OTP Verification</h2>
              <p>
                Please enter the 6 digit verification code sent to your
                registered email address.
              </p>
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-4 form-group">
                <Form.Label>OTP</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your OTP"
                  required
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </Form.Group>
              <Button type="submit" className="btn btn-primary w-100 mt-3">
                Proceed
              </Button>
              <p className="formfooter text-center mt-3">
                Back To <Link to="/">Login</Link>
              </p>
            </Form>
          </div>
        </Col>
        <Col lg={6}>
          <div className="loginlogo_div">
            <Link>
              <img src={logo} alt="" />
            </Link>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}
