import React, { useState, useEffect } from "react";
import { Card } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import DataTable from "react-data-table-component";
import { Link, useNavigate } from 'react-router-dom';
import { apiGetMethod } from "../../api"; // Adjust import according to your API utility
import { restaurant_deliveredOrderList } from "../../api/constants";

export default function OrdersDelivered() {
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiGetMethod({
        url: restaurant_deliveredOrderList,
        token,
      });

      if (response.data?.status) {
        setData(response.data.data);
      } else {
        console.error("Failed to fetch order data");
      }
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };

  const columns = [
    {
      name: 'Order ID',
      selector: row => row.orderId,
      sortable: true,
    },
    {
      name: 'Customer Name',
      selector: row => row.username,
      sortable: true,
    },
    {
      name: 'Date',
      selector: row => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
    },
    {
      name: 'Address',
      selector: row => row.userAddress,
      sortable: true,
    },
    {
      name: 'Payment Type',
      selector: row => row.status, // Assuming status as payment type for now
      sortable: true,
    },
    {
      name: 'Price',
      selector: row => `$${row.totalPrice}`,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: row => (
        <button
        className="btnview "
        onClick={() => navigate("/ViewOrders", { state: { orderId: row.orderId,OrdersDelivered:true } })}
      >
        <Icon icon="tabler:eye" />
      </button>
      ),
    },
  ];

  // Filter data based on search text
  const filteredData = data.filter(item =>
    item.username.toLowerCase().includes(searchText.toLowerCase()) ||
    item.userAddress.toLowerCase().includes(searchText.toLowerCase()) ||
    item.orderId.toLowerCase().includes(searchText.toLowerCase()),
  );

  return (
    <React.Fragment>
      <Card>
        <Card.Header className="py-3">
          <h5>Orders Delivered</h5>
        </Card.Header>
        <Card.Body>
          <div className="text-end mb-3">
            <input
              type="text"
              placeholder="Search..."
              className="searchfield"
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            responsive
            className="custom-table"
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
