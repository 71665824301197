import React from "react";
import { Row, Col, Card, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Item1 from "../assets/img/item1.jpg";
import Item2 from "../assets/img/item2.jpg";
import Item3 from "../assets/img/item3.jpg";
export default function ViewOrders2() {
    const Orders = {
        orderId: 'ORDR487933',
        date: 'March 11, 2023 at 09:12 AM',
        address: 'Melbourne, Australia',
        paymentType: 'Credit Card',
        customerName: 'Johnson Charles',
        customerEmail: 'johnsoncharles@gmail.com',
        customerPhone: '+91 9932474596',
        items: [
            { image: Item1, itemName: 'Classic Caesar Salad', description: 'Romaine lettuce, Caesar dressing, croutons, Parmesan cheese.', price: '$12.50' },
            { image: Item2, itemName: 'Margherita Pizza', description: 'Pizza dough, tomato sauce, fresh mozzarella, basil leaves.', price: '$28.15' },
            { image: Item3, itemName: 'Chicken Burger With Fries', description: 'Grilled chicken with veggies and fries.', price: '$19.25' },
        ],
    };
    return (
        <React.Fragment>
            <Card>
                <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
                    <h5>View Orders</h5>
                    <Link className="btn btn-primary d-inline-flex align-items-center px-4  mt-2 mt-md-0" to="/OrdersDelivered">Back To Orders</Link>
                </Card.Header>
                <Card.Body className="bookingtable">
                    <Table responsive>
                        <tbody>
                            <tr>
                                <td><b>Order ID</b></td>
                                <td>#{Orders.orderId}</td>
                            </tr>
                            <tr>
                                <td><b>Date</b></td>
                                <td>{Orders.date}</td>
                            </tr>
                            <tr>
                                <td><b>Deliver To</b></td>
                                <td>{Orders.address}</td>
                            </tr>
                            <tr>
                                <td><b>Payment Type</b></td>
                                <td>{Orders.paymentType}</td>
                            </tr>
                            <tr>
                                <td><b>Customer Info</b></td>
                                <td>
                                    <p>{Orders.customerName}</p>
                                    <p>{Orders.customerEmail}</p>
                                    <p className="mb-0">{Orders.customerPhone}</p>
                                </td>
                            </tr>
                            <tr>
                                <td><b>Order Details</b></td>
                                <td>
                                    <Table responsive className="tableinner">
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Description</th>
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Orders.items.map((item, index) => (
                                                <tr key={index}>
                                                    <td><img src={item.image} className="post-image img-fluid" alt={item.itemName} /></td>
                                                    <td>{item.itemName}</td>
                                                    <td>{item.description}</td>
                                                    <td>{item.price}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <Row className="justify-content-end vieworderdetail">
                                        <Col md={6} lg={4} xl={3}>
                                            <p>Item Total <span>$ 59.00</span></p>
                                            <p>Delivery Fee <span>$ 4.00</span></p>
                                            <p>Coupon(CHEF50) <span>- $ 12.00</span></p>
                                            <p><b>Total Amount</b> <b>$ 49.00</b></p>
                                        </Col>
                                    </Row>
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                </Card.Body>
            </Card>
        </React.Fragment>
    );

}