import React from "react";
import { Navigate, useLocation } from "react-router-dom";

export const PrivateRoute = ({ element }) => {
  const location = useLocation();
  const isAuthenticated = localStorage.getItem("token") ? true : false;

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return element;
};
