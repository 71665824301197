import axios from 'axios';
import { ENV } from './env';

const axiosInstance = axios.create({
  baseURL: ENV.BASE_URL,
});

const apiGetMethod = ({url, token}) => {
  let headers = {};
  if (token) headers.authorization = 'Bearer' + ' ' + token;
  return axiosInstance({
    method: 'get',
    url,
    headers,
  });
};
const apiPost = ({url, token, data = {}}) => {
  let headers = {};
  if (token) headers.authorization = 'Bearer' + ' ' + token;
  return axiosInstance({
    method: 'post',
    url,
    data,
    headers,
  });
};

const apiPutMethod = ({url, token, data = {}}) => {
  let headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  if (token) headers.authorization = 'Bearer' + ' ' + token;
  return axiosInstance({
    method: 'put',
    url,
    data,
    headers,
  });
};

export default axiosInstance;
export {apiGetMethod, apiPutMethod, apiPost};
