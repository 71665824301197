import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Button, Alert } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import { Link } from "react-router-dom";
import { apiPost } from "../../api";
import { loginUrl } from "../../api/constants";
import { toast } from "react-toastify";
import "../../themes/main.scss";
import { Icon } from "@iconify/react";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }
    try {
      const response = await apiPost({
        url: loginUrl(),
        data: { email, password },
      });
      if (response.data.status) {
        toast.success(response.data.message);
        localStorage.setItem("token", response.data?.data?.accessToken);
        localStorage.setItem("image", response.data?.data?.image);
        localStorage.setItem("refreshToken", response.data?.data?.refreshToken);
        localStorage.setItem("restaurantId", response.data?.data?.restaurantId);
        navigate("/Dashboard");
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={6} className="loginform">
          <div className="loginmain_div maxwidth500 mx-auto">
            <div className="headlogin_div mb-4">
              <h2>Welcome back! Glad to see you, Again!</h2>
            </div>
            <Form onSubmit={handleLogin}>
              <Form.Group className="mb-4 form-group">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-4 form-group">
                <Form.Label>Password</Form.Label>
                <div className="password-input-container">
                  <Form.Control
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Enter password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="password-input"
                  />
                  <Icon
                    icon={passwordVisible ? "mdi:eye-off" : "mdi:eye"}
                    className="password-toggle-icon"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-4 form-group forgotpassword text-end">
                <Link to="/ForgotPassword">Forgot Password?</Link>
              </Form.Group>
              <Button type="submit" className="btn btn-primary w-100">
                Login
              </Button>
              <p className="formfooter text-center mt-3">
                Don't have an account? <Link to="/SignUp">Sign Up</Link>
              </p>
            </Form>
          </div>
        </Col>
        <Col lg={6}>
          <div className="loginlogo_div">
            <Link>
              <img src={logo} alt="" />
            </Link>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}
