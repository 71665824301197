import React, { useState, useEffect } from "react";
import { Dropdown, Breadcrumb, Row, Col, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { ENV } from "../../api/env";
import { restaurant_statusUpdate } from "../../api/constants";
import { apiPost } from "../../api";

const Header = ({ title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch initial status from local storage
    const storedStatus = localStorage.getItem("restaurantStatus");
    if (storedStatus !== null) {
      setIsOpen(JSON.parse(storedStatus));
    }
  }, []);

  const handleToggle = async () => {
    const newStatus = !isOpen;
    setIsOpen(newStatus);
    localStorage.setItem("restaurantStatus", JSON.stringify(newStatus));

    try {
      const token = localStorage.getItem("token");
      const response = await apiPost({
        url: restaurant_statusUpdate,
        data: { type: newStatus },
        token,
      });

      if (response.status === 200) {
        console.log("Restaurant status updated");
      } else {
        console.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/", { replace: true });
    window.location.reload(); 
  };

  const image = localStorage.getItem("image");

  return (
    <header className="header">
      <Row>
        <Col xs={6}>
          <div className="mainbreadcrumb d-none d-md-block">
            <Breadcrumb>
              <Breadcrumb.Item href="#">Pages</Breadcrumb.Item>
              <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
            </Breadcrumb>
            <h1>{title}</h1>
          </div>
        </Col>
        <Col xs={6} className="text-end">
          <div
            className="headerright"
            style={{ display: "inline-flex", alignItems: "center" }}
          >
            <Form>
              <Form.Check
                type="switch"
                id="custom-switch"
                label={isOpen ? "Open" : "Close"}
                onChange={handleToggle}
                checked={isOpen}
              />
            </Form>
            <Link
              underline="none"
              className="mx-3 linksheader"
              to="/Notifications"
            >
              <Icon icon="solar:bell-outline" />
            </Link>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className="p-0">
                <img alt="" src={ENV?.S3_URL + image} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </header>
  );
};

export default Header;
