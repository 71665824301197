import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Form } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  restaurant_acceptrejectrequest,
  restaurant_dashboardCount,
  restaurant_dashboardUpcomingOrder,
} from "../../api/constants";
import { apiGetMethod, apiPost } from "../../api";
import { toast } from "react-toastify";
const data2 = [
  {
    name: "Jan",
    Value: 10,
  },
  {
    name: "Feb",
    Value: 50,
  },
  {
    name: "Mar",
    Value: 150,
  },
  {
    name: "Apr",
    Value: 100,
  },
  {
    name: "May",
    Value: 250,
  },
  {
    name: "Jun",
    Value: 400,
  },
  {
    name: "July",
    Value: 500,
  },
  {
    name: "Aug",
    Value: 300,
  },
  {
    name: "Sep",
    Value: 210,
  },
  {
    name: "Oct",
    Value: 115,
  },
  {
    name: "Nov",
    Value: 360,
  },
  {
    name: "Dec",
    Value: 450,
  },
];

const data1 = [
  {
    name: "Jan",
    Value: 30,
  },
  {
    name: "Feb",
    Value: 20,
  },
  {
    name: "Mar",
    Value: 150,
  },
  {
    name: "Apr",
    Value: 230,
  },
  {
    name: "May",
    Value: 300,
  },
  {
    name: "Jun",
    Value: 200,
  },
  {
    name: "July",
    Value: 150,
  },
  {
    name: "Aug",
    Value: 340,
  },
  {
    name: "Sep",
    Value: 450,
  },
  {
    name: "Oct",
    Value: 231,
  },
  {
    name: "Nov",
    Value: 375,
  },
  {
    name: "Dec",
    Value: 401,
  },
];
const gradientColors = ["#ddc45c ", "#fff"];
const formatYAxisTick = (value) => value;
const formatYAxisTick2 = (value) => `$ ${value}`;
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{` ${payload[0].value} Orders`}</p>
      </div>
    );
  }

  return null;
};
const CustomTooltip2 = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`$ ${payload[0].value} `}</p>
      </div>
    );
  }

  return null;
};
export default function Dashboard() {
  // const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [stats, setStats] = useState({
    orderCount: 0,
    deliverOrderCount: 0,
    revenue: "$0",
  });
  const [orders, setOrders] = useState([]);
  const columns = [
    {
      name: "Order ID",
      selector: (row) => row.orderId,
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Total Price",
      selector: (row) => row.totalPrice,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.orderStatus,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) =>
        `${row.userAddress.addressLine1}, ${row.userAddress.city}, ${row.userAddress.state}, ${row.userAddress.country}`,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => new Date(row.createdAt).toLocaleString(),
      sortable: true,
    },
    {
      name: "Actions",
      cell: row => (
        <div>
          <Link
            to="#"
            onClick={() => handleRequest(row.orderId, 'Accept')}
            className="btnview me-2"
          >
            <Icon icon="mdi:check" />
          </Link>
          <Link
            to="#"
            onClick={() => handleRequest(row.orderId, 'Reject')}
            className="btnedit"
          >
            <Icon icon="mdi:close" />
          </Link>
        </div>
      ),
    },
  ];
  const handleRequest = async (orderId, actionType) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiPost({
        url: restaurant_acceptrejectrequest,
        data: {
          orderId,
          type: actionType
        },
        token
      });

      if (response.data?.status) {
        toast.success(`${actionType.toLowerCase()} order successful`);
        setOrders(orders.filter(order => order.orderId !== orderId));
      } else {
        console.error(`Failed to ${actionType.toLowerCase()} order`);
      }
    } catch (error) {
      console.error(`Error ${actionType.toLowerCase()} order:`, error);
    }
  };
  const filteredData = orders.filter(
    (order) =>
      order.orderId.toLowerCase().includes(searchText.toLowerCase()) ||
      order.username.toLowerCase().includes(searchText.toLowerCase())
  );
  useEffect(() => {
    fetchDashboardCounts();
    fetchUpcomingOrders();
  }, []);
  const fetchUpcomingOrders = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiGetMethod({
        url: restaurant_dashboardUpcomingOrder,
        token,
      });

      if (response.status === 200) {
        setOrders(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching upcoming orders:", error);
    }
  };

  const fetchDashboardCounts = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiGetMethod({
        url: restaurant_dashboardCount,
        token,
      });

      if (response.status === 200) {
        setStats(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching dashboard counts:", error);
    }
  };
  return (
    <React.Fragment>
      <Row>
        <Col lg={4}>
          <div className="stats">
            <span className="statsicon">
              <Icon icon="solar:cart-5-bold" />
            </span>
            <div className="stats-cont">
              <h4>Total Orders</h4>
              <p>{stats.orderCount}</p>
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="stats">
            <span className="statsicon statsblue">
              <Icon icon="material-symbols-light:order-approve" />
            </span>
            <div className="stats-cont">
              <h4>Total Orders Delivered</h4>
              <p>{stats.deliverOrderCount}</p>
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="stats">
            <span className="statsicon statsgreen">
              <Icon icon="solar:graph-up-broken" />
            </span>
            <div className="stats-cont">
              <h4>Revenue Generated</h4>
              <p>{stats.revenue}</p>
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <Card className="mt-4">
            <Card.Header className="d-flex align-items-center justify-content-between">
              <h5>Total Orders</h5>{" "}
              <Form.Select className="maxwidth120">
                <option value="1">Weekly</option>
                <option value="2" selected>
                  Monthly
                </option>
                <option value="3">Yearly</option>
              </Form.Select>
            </Card.Header>
            <Card.Body>
              <ResponsiveContainer width="100%" height={300}>
                <AreaChart
                  data={data2}
                  margin={{
                    top: 20,
                    right: 0,
                    left: -20,
                    bottom: 0,
                  }}
                >
                  {" "}
                  <defs>
                    <linearGradient
                      id="areaGradient"
                      x1="0"
                      y1="1"
                      x2="0"
                      y2="0"
                    >
                      <stop
                        offset="5%"
                        stopColor={gradientColors[0]}
                        stopOpacity={0.5}
                      />
                      <stop
                        offset="95%"
                        stopColor={gradientColors[1]}
                        stopOpacity={0.1}
                      />
                    </linearGradient>
                  </defs>
                  <CartesianGrid stroke="#EAEAEA" strokeWidth={1} />
                  <XAxis
                    dataKey="name"
                    stroke="none"
                    tick={{ fill: "black", fontSize: 12 }}
                  />
                  <YAxis
                    tickFormatter={formatYAxisTick}
                    stroke="none"
                    tick={{ fill: "black", fontSize: 12 }}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Area
                    type="monotone"
                    dataKey="Value"
                    stroke="#ddc45c " // Dark curve line color
                    fill="url(#areaGradient)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card className="mt-4">
            <Card.Header className="d-flex align-items-center justify-content-between">
              <h5>Total Revenue</h5>{" "}
              <Form.Select className="maxwidth120">
                <option value="1">Weekly</option>
                <option value="2" selected>
                  Monthly
                </option>
                <option value="3">Yearly</option>
              </Form.Select>
            </Card.Header>
            <Card.Body>
              <ResponsiveContainer width="100%" height={300}>
                <AreaChart
                  data={data1}
                  margin={{
                    top: 20,
                    right: 0,
                    left: -10,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid stroke="#EAEAEA" strokeWidth={1} />
                  <XAxis
                    dataKey="name"
                    stroke="none"
                    tick={{ fill: "black", fontSize: 12 }}
                  />
                  <YAxis
                    tickFormatter={formatYAxisTick2}
                    stroke="none"
                    tick={{ fill: "black", fontSize: 12 }}
                  />
                  <Tooltip content={<CustomTooltip2 />} />
                  <Area
                    type="monotone"
                    dataKey="Value"
                    stroke="#7367f0"
                    fill="#b09ff5"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={12}>
          <Card className="mt-4">
            <Card.Header className="py-3">
              <h5>Upcoming Orders</h5>
            </Card.Header>
            <Card.Body>
              <div className="text-end mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  className="searchfield"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                responsive
                className="custom-table"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
