import React, { useEffect, useState } from "react";
import { Modal, Card, Form, Button, Row, Col } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { apiGetMethod, apiPost } from "../../api";
import {
  restaurant_addItem,
  restaurant_deleteItem,
  restaurant_editItem,
  restaurant_viewcategory,
} from "../../api/constants";
import { ENV } from "../../api/env";
import "../../themes/main.scss";
import { toast } from "react-toastify";

export default function Menuitems() {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const [itemData, setItemData] = useState({
    name: "",
    description: "",
    image: null,
  });
  const [sizes, setSizes] = useState([{ type: "", price: "" }]);
  const [extraAddOn, setExtraAddOn] = useState([{ name: "", price: "" }]);
  const [doneItems, setDoneItems] = useState(new Array(1).fill(false));
  const [doneAddOns, setDoneAddOns] = useState(new Array(1).fill(false));
  const [items, setItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [image, setImage] = useState(null);
  const [editImage, seteditImage] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setItemData({ ...itemData, [name]: value });
  };

  //edit api
  const [isEditing, setIsEditing] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const handleShow2 = (item) => {
    setCurrentItem(item);
    setItemData({
      name: item.name,
      description: item.ingredients,
      image: null,
    });
    setSizes(item.price || [{ type: "", price: "" }]);
    setExtraAddOn(
      item?.extraAddOn.length > 0 ? item.extraAddOn : [{ name: "", price: "" }]
    );
    setDoneItems(new Array(item.price.length).fill(true));
    setDoneAddOns(new Array(item.extraAddOn.length).fill(true));
    setIsEditing(true);
    seteditImage(ENV.S3_URL + item.image);
    handleShow();
  };

  const handleExtraAddOnChange = (index, key, value) => {
    const newExtraAddOn = [...extraAddOn];
    newExtraAddOn[index][key] = value;
    setExtraAddOn(newExtraAddOn);
  };
  const handleSizeAndPriceChange = (index, key, value) => {
    const newExtraAddOn = [...sizes];
    newExtraAddOn[index][key] = value;
    setSizes(newExtraAddOn);
  };

  const addSizeAndPrice = () => {
    setSizes([...sizes, { type: "", price: "" }]);
    setDoneItems([...doneItems, false]);
  };

  const addExtraAddOn = () => {
    setExtraAddOn([...extraAddOn, { name: "", price: "" }]);
    setDoneAddOns([...doneAddOns, false]);
  };

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };
  const handleRemoveFile = () => {
    setImage(null);
  };

  const handleDoneClick = (index) => {
    const newDoneItems = [...doneItems];
    newDoneItems[index] = true;
    setDoneItems(newDoneItems);
  };

  const handleRemoveClick = (index) => {
    const newSizes = sizes.filter((_, i) => i !== index);
    const newDoneItems = doneItems.filter((_, i) => i !== index);
    setSizes(newSizes);
    setDoneItems(newDoneItems);
  };

  const handleDoneAddOnClick = (index) => {
    const newDoneAddOns = [...doneAddOns];
    newDoneAddOns[index] = true;
    setDoneAddOns(newDoneAddOns);
  };

  const handleRemoveAddOnClick = (index) => {
    const newExtraAddOn = extraAddOn.filter((_, i) => i !== index);
    const newDoneAddOns = doneAddOns.filter((_, i) => i !== index);
    setExtraAddOn(newExtraAddOn);
    setDoneAddOns(newDoneAddOns);
  };

  const handleAddItem = async (e) => {
    e.preventDefault();
    if (!itemData.name || !itemData.description || sizes.length === 0) {
      toast.error("Please fill all the required fields.");
      return;
    }
    if (image === null) {
      toast.error("Please Add the image required");
      return;
    }
    const formData = new FormData();
    formData.append("categoryId", id);
    formData.append("name", itemData.name);
    formData.append("ingredients", itemData.description);
    formData.append("price", JSON.stringify(sizes));
    formData.append("extraAddOn", JSON.stringify(extraAddOn));
    if (image) {
      formData.append("image", image);
    }

    try {
      const token = localStorage.getItem("token");
      const response = await apiPost({
        url: restaurant_addItem,
        token,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data.status) {
        toast.success(response.data.message);
        fetchItems();
        handleClose();
        setExtraAddOn([{ name: "", price: "" }]);
        setSizes([{ type: "", price: "" }]);
        setItemData({ name: "", description: "", image: null });
      }
    } catch (error) {
      console.error("Failed to add item", error);
    }
  };
  const handleEditItem = async (e) => {
    e.preventDefault();
    if (!itemData.name || !itemData.description || sizes.length === 0) {
      toast.error("Please fill all the required fields.");
      return;
    }
    const formData = new FormData();
    formData.append("itemId", currentItem?._id);
    formData.append("name", itemData.name);
    formData.append("ingredients", itemData.description);
    formData.append("price", JSON.stringify(sizes));
    formData.append("extraAddOn", JSON.stringify(extraAddOn));
    if (image) {
      formData.append("image", image);
    }

    try {
      const token = localStorage.getItem("token");
      const response = await apiPost({
        url: restaurant_editItem,
        token,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data.status) {
        toast.success(response.data.message);
        fetchItems();
        handleClose();
        setExtraAddOn([{ name: "", price: "" }]);
        setSizes([{ type: "", price: "" }]);
        setItemData({ name: "", description: "", image: null });
        seteditImage("");
      }
    } catch (error) {
      console.error("Failed to edit item", error);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiGetMethod({
        url: `${restaurant_viewcategory}?categoryId=${id}`,
        token,
      });
      if (response.data.status) {
        setItems(response.data.data);
      } else {
        console.error("Failed to fetch items", response.data.message);
      }
    } catch (error) {
      console.error("Failed to fetch items", error);
    }
  };
  const handleDeleteItem = async (itemId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiPost({
        url: restaurant_deleteItem,
        token,
        data: { itemId },
      });
      if (response.data.status) {
        toast.success(response.data.message);
        fetchItems();
      }
    } catch (error) {
      console.error("Failed to delete item", error);
    }
  };
  const columns = [
    {
      name: "Image",
      sortable: true,
      maxWidth: "100px",
      selector: (row) => (
        <img
          src={ENV.S3_URL + row.image}
          alt={row.name}
          style={{ width: "50px", height: "50px", objectFit: "cover" }}
        />
      ),
    },
    {
      name: "Name",
      sortable: true,
      maxWidth: "100px",
      selector: (row) => row.name,
    },
    {
      name: "Description",
      sortable: true,
      maxWidth: "300px",
      selector: (row) => row.ingredients,
    },
    {
      name: "Sizes & Prices",
      sortable: true,
      maxWidth: "300px",
      selector: (row) => (
        <ul style={{ paddingLeft: 0 }}>
          {Array.isArray(row.price)
            ? row.price.map((p, index) => (
                <li key={index}>
                  {p.type}: ${p.price}
                </li>
              ))
            : "No sizes available"}
        </ul>
      ),
    },
    {
      name: "Extra Add-Ons",
      sortable: true,
      maxWidth: "350px",
      selector: (row) => (
        <ul style={{ paddingLeft: 0 }}>
          {Array.isArray(row.extraAddOn)
            ? row.extraAddOn.map((addOn, index) => (
                <li key={index}>
                  {addOn.name}: ${addOn.price}
                </li>
              ))
            : "No extra add-ons available"}
        </ul>
      ),
    },
    {
      name: "Actions",
      sortable: true,
      maxWidth: "150px",
      cell: (row) => (
        <div>
          <Link
            className="btnedit"
            onClick={() => {
              handleShow2(row);
            }}
          >
            <Icon icon="tabler:pencil" />
          </Link>
          <Link
            className="btndelete"
            onClick={() => {
              handleDeleteItem(row._id);
            }}
          >
            <Icon icon="tabler:trash" />
          </Link>
        </div>
      ),
    },
  ];

  const filteredData = (items || []).filter(
    (item) =>
      item?.name.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.ingredients.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between">
          <h5>Menu Items</h5>
          <Button className="btn btn-primary px-4" onClick={handleShow}>
            Add Item
          </Button>
        </Card.Header>
        <Card.Body>
          <div className="text-end mb-3">
            <input
              type="text"
              placeholder="Search..."
              className="searchfield"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            responsive
            className="custom-table"
          />
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose} centered className="itemmodal">
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="modalhead">
              {isEditing ? "Update Item" : "Add New Item"}
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              if (isEditing) {
                handleEditItem(e);
              } else {
                handleAddItem(e);
              }
            }}
          >
            <Row className="gx-2">
              <Col lg={12}>
                <Form.Group className="mb-3 form-group">
                  <Form.Control
                    type="text"
                    placeholder="Item Name"
                    name="name"
                    value={itemData.name}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3 form-group">
              <Form.Control
                as="textarea"
                placeholder="Enter description"
                rows={5}
                name="description"
                value={itemData.description}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Label>Price</Form.Label>
            {sizes.map((size, index) => (
              <Row className="gx-2 align-items-center" key={index}>
                <Col xs={5} className="mb-3">
                  <Form.Group className="form-group">
                    <Form.Control
                      type="text"
                      placeholder="Size"
                      value={size?.type}
                      onChange={(e) =>
                        handleSizeAndPriceChange(index, "type", e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col xs={5} className="mb-3">
                  <Form.Group className="form-group">
                    <Form.Control
                      type="text"
                      placeholder="Price"
                      value={size?.price}
                      onChange={(e) =>
                        handleSizeAndPriceChange(index, "price", e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col xs={2} className="mb-3">
                  {doneItems[index] ? (
                    <div>
                      {index > 0 && (
                        <Link
                          className="addicon"
                          onClick={() => handleRemoveClick(index)}
                        >
                          <Icon icon="tabler:minus" />
                        </Link>
                      )}
                      {index === 0 && (
                        <Link className="addicon" onClick={addSizeAndPrice}>
                          <Icon icon="tabler:plus" />
                        </Link>
                      )}
                    </div>
                  ) : (
                    <Link
                      className="addicon"
                      onClick={() => handleDoneClick(index)}
                    >
                      <Icon icon="tabler:check" />
                    </Link>
                  )}
                </Col>
              </Row>
            ))}
            <Form.Label>Extra Add-On</Form.Label>
            {extraAddOn.map((addOn, index) => (
              <Row className="gx-2 align-items-center" key={index}>
                <Col xs={5} className="mb-3">
                  <Form.Group className="form-group">
                    <Form.Control
                      type="text"
                      placeholder="Extra Add-On"
                      value={addOn.name}
                      onChange={(e) =>
                        handleExtraAddOnChange(index, "name", e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col xs={5} className="mb-3">
                  <Form.Group className="form-group">
                    <Form.Control
                      type="text"
                      placeholder="Price"
                      value={addOn.price}
                      onChange={(e) =>
                        handleExtraAddOnChange(index, "price", e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col xs={2} className="mb-3">
                  {doneAddOns[index] ? (
                    <div>
                      {index > 0 && (
                        <Link
                          className="addicon"
                          onClick={() => handleRemoveAddOnClick(index)}
                        >
                          <Icon icon="tabler:minus" />
                        </Link>
                      )}
                      {index === 0 && (
                        <Link className="addicon" onClick={addExtraAddOn}>
                          <Icon icon="tabler:plus" />
                        </Link>
                      )}
                    </div>
                  ) : (
                    <div>
                      <Link
                        className="addicon"
                        onClick={() => handleDoneAddOnClick(index)}
                      >
                        <Icon icon="tabler:check" />
                      </Link>
                      {/* {index === 0 && (
                        <Link
                          className="addicon"
                          onClick={addExtraAddOn}
                          style={{ marginLeft: 10 }}
                        >
                          <Icon icon="tabler:plus" />
                        </Link>
                      )} */}
                    </div>
                  )}
                </Col>
              </Row>
            ))}
            <Form.Group className="form-group mb-4 postimgvideo">
              {editImage ? (
                <div className="mt-2 image-preview">
                  <img src={editImage} alt="Uploaded" className="img-fluid" />

                  <div
                    className="close-icon"
                    onClick={() => {
                      seteditImage("");
                    }}
                  >
                    <Icon icon="mdi:close" />
                  </div>
                </div>
              ) : (
                <>
                  {image ? (
                    <div className="mt-2 image-preview">
                      <img
                        src={URL.createObjectURL(image)}
                        alt="Uploaded"
                        className="img-fluid"
                      />

                      <div className="close-icon" onClick={handleRemoveFile}>
                        <Icon icon="mdi:close" />
                      </div>
                    </div>
                  ) : (
                    <>
                      <Form.Label htmlFor="uploadimg">
                        <Icon icon="ion:image-outline" />
                      </Form.Label>
                      <Form.Control
                        type="file"
                        id="uploadimg"
                        onChange={handleFileChange}
                        className="d-none"
                      />
                    </>
                  )}
                </>
              )}
            </Form.Group>
            <div className="text-center mt-4">
              {isEditing ? (
                <Button variant="primary" type="submit">
                  Update
                </Button>
              ) : (
                <Button variant="primary" type="submit">
                  Save
                </Button>
              )}
              <Button
                variant="secondary"
                onClick={handleClose}
                className="ms-2"
              >
                Close
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
