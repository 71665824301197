import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { apiGetMethod, apiPost } from "../../api"; // Import your API methods
import {
  restaurant_orderList,
  restaurant_updateOrderStatus,
} from "../../api/constants";

export default function OrdersManagement() {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    fetchOrders();
  }, []);
  const fetchOrders = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiGetMethod({
        url: restaurant_orderList,
        token,
      });

      if (response.status === 200) {
        setData(response.data.data);
      } else {
        console.error("Failed to fetch orders");
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const handleStatusChange = async (orderId, newStatusNumber) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiPost({
        url: restaurant_updateOrderStatus,
        data: {
          orderId,
          status: newStatusNumber,
        },
        token,
      });
      if (response.data?.status) {
        const updatedData = data.map((item) =>
          item.orderId === orderId
            ? {
                ...item,
                status: response.data.data.status,
                statusNumber: response.data.data.statusNumber,
              }
            : item
        );
        setData(updatedData);
      }
    } catch (error) {
      console.error(`Error updating status to ${newStatusNumber}:`, error);
    }
  };

  const columns = [
    {
      name: "Order ID",
      selector: (row) => row.orderId,
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.userAddress,
      sortable: true,
    },
    // {
    //   name: "Status",
    //   cell: (row) => {
    //     const isStatusCompleted = row.statusNumber === 13;
    //     const isPendingDisabled = isStatusCompleted || row.statusNumber !== 16;
    //     const isInProgressDisabled =
    //       isStatusCompleted || row.statusNumber !== 11;
    //     const isCancelledDisabled =
    //       isStatusCompleted || row.statusNumber !== 17;

    //     return (
    //       <select
    //         className="tabledropdown form-select"
    //         value={row.statusNumber}
    //         onChange={(e) =>
    //           handleStatusChange(row._id, parseInt(e.target.value))
    //         }
    //       >
    //         <option value="16" disabled={isPendingDisabled}>
    //           Pending
    //         </option>
    //         <option value="11" disabled={isInProgressDisabled}>
    //           In Progress
    //         </option>
    //         <option value="13" disabled={isStatusCompleted}>
    //           Completed
    //         </option>
    //         <option value="17" disabled={isCancelledDisabled}>
    //           Cancelled
    //         </option>
    //       </select>
    //     );
    //   },
    // },
    {
      name: "Price",
      selector: (row) => `$${row.totalPrice}`,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <button
          className="btnview "
          onClick={() =>
            navigate("/ViewOrders", {
              state: { orderId: row.orderId, OrdersDelivered: false },
            })
          }
        >
          <Icon icon="tabler:eye" />
        </button>
      ),
    },
  ];

  const filteredData = data.filter(
    (item) =>
      item.username.toLowerCase().includes(searchText.toLowerCase()) ||
      item.userAddress.toLowerCase().includes(searchText.toLowerCase()) ||
      item.orderId.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <React.Fragment>
      <Card>
        <Card.Header className="py-3">
          <h5>Orders Management</h5>
        </Card.Header>
        <Card.Body>
          <div className="text-end mb-3">
            <input
              type="text"
              placeholder="Search..."
              className="searchfield"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            responsive
            className="custom-table"
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
