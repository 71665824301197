import React, { useState, useEffect } from "react";
import { Row, Col, Tab, Tabs, Form, Card, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "../assets/img/item4.jpg";
import Autocomplete from "react-google-autocomplete";

import {
  restaurant_profile,
  restaurant_updateProfile,
  restaurant_changePassword,
} from "../../api/constants";
import { apiGetMethod, apiPost } from "../../api";
import { toast } from "react-toastify";
import { ENV } from "../../api/env";

export default function EditProfile() {
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState({});
  
  const [timeValue, setTimeValue] = useState("");
  const [timeValue2, setTimeValue2] = useState("");
  const [bio, setBio] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [newProfilePic, setNewProfilePic] = useState(null);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [key, setKey] = useState("basicinfo");
  const [searchPlace, setPlace] = useState("");
  const [formData, setFormData] = useState({
    title: title,
    name: name,
    description: bio,
    openTime: timeValue,
    closeTime: timeValue2,
    location: {},
    email: email,
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiGetMethod({
        url: restaurant_profile,
        token,
      });

      if (response.status === 200) {
        const profileData = response.data.data;
        setName(profileData.companyName || "");
        setTitle(profileData.title || "");
        setEmail(profileData.email || "");
        setPlace(profileData.location || {});
        setTimeValue(profileData.openTime || "");
        setTimeValue2(profileData.closeTime || "");
        setBio(profileData.description || "");
        setProfilePic(ENV.S3_URL + profileData.image || Avatar);
      } else {
        console.error("Failed to fetch profile data");
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const handlePlaceSelect = (place) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    setPlace(place.formatted_address || "");
    setLocation({
      name: place.formatted_address,
      latitude: lat,
      longitude: lng,
    });
  };



  const handleUpdateProfile = async () => {
    if (!name || !title || !email || !location || !timeValue || !bio) {
      toast.error("All fields except profile picture are required");
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("name", name);
      formData.append("title", title);
      formData.append("email", email);
      formData.append("location",  JSON.stringify(location));
      formData.append("openTime", timeValue);
      formData.append("closeTime", timeValue2);
      formData.append("bio", bio);

      if (newProfilePic) {
        formData.append("image", newProfilePic);
      }

      const response = await apiPost({
        url: restaurant_updateProfile,
        data: formData,
        token,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        toast.success("Profile updated successfully");
        navigate("/ProfileSettings");
      } else {
        console.error("Failed to update profile");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handlePasswordChange = async () => {
    if (!currentPassword || !newPassword || !confirmPassword) {
      toast.error("All fields are required");
      return;
    }

    if (newPassword.length < 6) {
      toast.error("New password must be at least 6 characters long");
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("New passwords do not match");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await apiPost({
        url: restaurant_changePassword,
        data: {
          currentPassword,
          newPassword,
        },
        token,
      });

      if (response.data?.status) {
        toast.success("Password changed successfully");
        navigate("/ProfileSettings");
      } else {
        console.error("Failed to change password");
      }
    } catch (error) {
      console.error("Error changing password:", error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      setNewProfilePic(file);
      setProfilePic(URL.createObjectURL(file));
    } else {
      toast.error("Only PNG and JPG files are allowed");
    }
  };

  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Edit Profile</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 mt-2 mt-md-0"
            to="/ProfileSettings"
          >
            Back To Settings
          </Link>
        </Card.Header>
        <Card.Body>
          <Tabs
            id="profile-tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-2"
          >
            <Tab eventKey="basicinfo" title="Basic Info">
              <Row>
                <Col md={5} xl={4} className="view_profile mb-3">
                  <div className="user_info text-center">
                    <img
                      src={profilePic}
                      className="rounded-circle img-fluid profilepic"
                      alt="Profile"
                    />
                    <label
                      htmlFor="uploadprofileimg"
                      className="d-block mt-3 btn btn-primary-outline px-4 font-size-14 maxwidth120 mx-auto lh-40"
                    >
                      Choose
                    </label>
                    <input
                      type="file"
                      id="uploadprofileimg"
                      className="d-none"
                      onChange={handleFileChange}
                    />
                  </div>
                </Col>
                <Col md={7} xl={8}>
                  <Form>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Company Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3 form-group">
                          <Form.Label>Title</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3 form-group">
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            readOnly
                            // required
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3 form-group">
                          <Form.Label>Location</Form.Label>
                          <div className="autocomplete-wrapper">
                      <Autocomplete
                        apiKey={ENV.GOOGLE_MAP_APIKEY}
                        onPlaceSelected={(place) => handlePlaceSelect(place)}
                        value={searchPlace}
                        onChange={(e) => setPlace(e.target.value)}
                        placeholder="Enter the location"
                        required
                        className="form-control"
                        types={["address"]}
                      />
                    </div>
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Opening Hours</Form.Label>
                          <Row>
                            <Col lg={6}>
                              <Form.Control
                                type="time"
                                value={timeValue}
                                onChange={(e) => setTimeValue(e.target.value)}
                                className="mb-3"
                                required
                              />
                            </Col>
                            <Col lg={6}>
                              <Form.Control
                                type="time"
                                value={timeValue2}
                                onChange={(e) => setTimeValue2(e.target.value)}
                                className="mb-3"
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="mb-3 form-group">
                          <Form.Label>Bio</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={4}
                            value={bio}
                            onChange={(e) => setBio(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      className="btn btn-primary px-4"
                      onClick={handleUpdateProfile}
                    >
                      Update
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="changepassword" title="Change Password">
              <Row>
                <Col md={8} xl={9}>
                  <Form>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Current Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Current Password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>New Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Confirm New Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Confirm New Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      className="btn btn-primary px-4"
                      onClick={handlePasswordChange}
                    >
                      Update
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
