import { ENV } from "./env";
const signupRestaurant = () => {
  return ENV.BASE_URL + "signup_restaurant";
};
const verifiOtpUrl = () => {
  return ENV.BASE_URL + "verifyOTP";
};
const resentOtpUrl = () => {
  return ENV.BASE_URL + "resendOTP";
};
const forgotPasswordUrl = () => {
  return ENV.BASE_URL + "forgotpassword";
};
const loginUrl = () => {
  return ENV.BASE_URL + "login_restaurant";
};
const refreshToken = () => {
  return ENV.BASE_URL + "refreshToken";
};
const resetPasswordUrl = () => {
  return ENV.BASE_URL + "resetpassword";
};
const fetchProfileUrl = () => {
  return ENV.BASE_URL + "fetchprofile";
};
const fetchPrivacyPolicyUrl = () => {
  return ENV.BASE_URL + "fetch_PrivacyPolicy";
};
const contactUsUrl = () => {
  return ENV.BASE_URL + "contactus";
};
export const restaurant_verifyOTP = "restaurant_verifyOTP";
export const restaurant_forgotPassword = "restaurant_forgotPassword";
export const restaurant_resetPassword = "restaurant_resetPassword";
export const restaurant_profile = "restaurant_profile";
export const restaurant_updateProfile = "restaurant_updateProfile";
export const restaurant_changePassword = "restaurant_changePassword";
export const restaurant_statusUpdate = "restaurant_statusUpdate";
export const restaurant_dashboardCount = "restaurant_dashboardCount";
export const restaurant_dashboardUpcomingOrder = "restaurant_dashboardUpcomingOrder";
export const restaurant_acceptrejectrequest = "restaurant_acceptrejectrequest";
export const restaurant_orderList = "restaurant_orderList";
export const restaurant_updateOrderStatus = "restaurant_updateOrderStatus";
export const restaurant_viewOrderDetail = "restaurant_viewOrderDetail";
export const restaurant_deliveredOrderList = "restaurant_deliveredOrderList";
export const restaurant_helplist = "restaurant_helplist";
export const restaurant_addQAndA = "restaurant_addQAndA";
export const restaurant_editQAndA = "restaurant_editQAndA";
export const restaurant_deleteQAndA = "restaurant_deleteQAndA";
export const restaurant_categorylist = "restaurant_categorylist";
export const restaurant_addcategory = "restaurant_addcategory";
export const restaurant_editcategory = "restaurant_editcategory";
export const restaurant_deletecategory = "restaurant_deletecategory";
export const restaurant_viewcategory = "restaurant_viewcategory";
export const restaurant_addItem = "restaurant_addItem";
export const restaurant_editItem = "restaurant_editItem";
export const restaurant_deleteItem = "restaurant_deleteItem";
export const restaurant_notification = "restaurant_notification";

export {
  signupRestaurant,
  verifiOtpUrl,
  resentOtpUrl,
  forgotPasswordUrl,
  loginUrl,
  resetPasswordUrl,
  fetchProfileUrl,
  fetchPrivacyPolicyUrl,
  contactUsUrl,
  refreshToken,
};
